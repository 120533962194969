<template>
	<!-- <div class="title" :class="{bgVip: hasCode !== 1}"> -->
	<div class="title">
		<!-- <div v-if="hasCode == 1"> -->
			<!-- <div class="commonHead1" style="padding-top: env(safe-area-inset-top);">
				<div class="head">
					<div class="left" @click="onClickLeft">
						<div class="icon"><i class="icon_arrL"></i></div>
					</div>
					<div class="title">{{ $t('tba.invitefriends') }}</div>
				</div>
			</div> -->
			<common-header
				bg_size="100% calc(env(safe-area-inset-top) + 100vh)"
				:title="$t('tba.invitefriends')"
				:bg_src="require('../../assets/imgs/yao_bg.png')"
				:is-return="true"
				style="padding-top: env(safe-area-inset-top);"
			>
			</common-header>
			<!-- <common-head url="/newHome" :title="$t('tba.invitefriends')" :is-return="true"></common-head> -->

			<div class="layoutBox">
				<div class="shareAds">
					<img src="@/assets/imgs/yao_zi.png" alt="" />
				</div>
				<div class="yao_head_zi">
					邀请好友完成注册获得相应奖励
				</div>
				<div class="shareAds1">
					<img src="@/assets/imgs/yao_tu.png" alt="" />
				</div>
				<div class="shareInfo">
					<div class="top">
						<div class="item">
							<div class="item_left">
								{{ $t('tba.Myinvitationcode') }}
								<div class="inp">{{ inviteCode }}</div>
							</div>
							<van-button class="copy copyCode" :data-clipboard-text="inviteCode" @click="copy2" text="复制"></van-button>
						</div>
						<div class="item1">
							<div class="item_left">
								{{ $t('tba.Myinvitationlink') }}
								<div class="inp">
									<div class="link">{{ link }}</div>
								</div>
							</div>
							<van-button class="copy copyLink" :data-clipboard-text="link" @click="copy" text="复制"></van-button>
						</div>
					</div>
					<div class="shareSteps">
						<div class="item">
							<div class="icon">01</div>
							<div class="txt">{{ $t('tba.sendinvitationTofriends') }}</div>
						</div>
						<div class="split"><img src="@/assets/imgs/dian.png" /></div>
						<div class="item1">
							<div class="icon">02</div>
							<div class="txt">{{ $t('tba.Friendscompleteregistrationandactivation') }}</div>
						</div>
						<div class="split"><img src="../../assets/imgs/dian.png" /></div>
						<div class="item">
							<div class="icon">03</div>
							<div class="txt">{{ $t('tba.Getrewardsinproportion') }}</div>
						</div>
					</div>
				</div>
				<div class="bottom">
					<div class="btn btnL" @click="routerTo('/sharePoster')">{{ $t('tba.Generateinvitationposter') }}</div>
					<div class="btn btnR" @click="showPoster">{{ $t('team.FaceFaceInvitation') }}</div>
				</div>
			</div>
		<!-- <div v-else>
			<common-head :is-return="true"></common-head>
			<div class="shareFooter">
				<van-button @click="gotoMember" class="footerBtn">{{ $t('tba.toBeVIP') }}</van-button>
			</div>
		</div> -->

		<!-- 面对面邀请-弹出 -->
		<div class="posterLayout" v-show="isShowPoster"></div>
		<div class="posterCode" v-show="isShowPoster">
			<div class="poster">
				<!-- <img src="@/assets/imgs/share/invitation_poster.png" alt=""> -->
				<div class="title">面对面扫码邀请</div>
				<div class="code"><div id="share_page_qrcode" style="width: 100%;height: 100%" ref="share_page_qrcode"></div></div>
			</div>
			<div class="close" @click="closePoster"></div>
		</div>

		<!-- 邀请链接+邀请码 -->
		<div style="display: none">
			<van-field v-model="link" :name="$t('team.InviteLink')" :label="$t('team.InviteLink')" :placeholder="$t('team.InviteLink')" />
			<van-field v-model="inviteCode" :name="$t('team.InviteCode')" :label="$t('team.InviteCode')" :placeholder="$t('team.InviteCode')" />
		</div>
	</div>
</template>

<script>
import Clipboard from 'clipboard';
// 地址转换二维码插件
import QRCode from 'qrcodejs2';
import commonHeader from '@/components/commonHeader';
export default {
	name: 'sharePage',
	components: { commonHeader },
	data() {
		return {
			msg: '<',
			inviteCode: '',
			link: '',
			hasCode: 1,
			// 20200410----
			isShowPoster: false
			// 20210415-----
		};
	},
	mounted() {
		this.getInviteCode();
	},
	methods: {
		onClickLeft() {
			this.$router.back(-1);
		},
		routerTo(path) {
			this.$router.push(path);
		},
		// ------20200410[start]----
		showPoster() {
			this.isShowPoster = true;
		},
		closePoster() {
			this.isShowPoster = false;
		},
		// ------20200410[end]----
		gotoMember() {
			this.$router.push({
				path: '/memberClub'
			});
		},
		// 复制
		copy() {
			var clipboard = new Clipboard('.copyLink');
			clipboard.on('success', e => {
				// this.$dialog.alert({
				// 	message: this.$t('xinzeng.fuzhichengg')
				// });
				this.$toast(this.$t('xinzeng.fuzhichengg'));
				// 释放内存
				clipboard.destroy();
			});
		},
		copy2() {
			var clipboard = new Clipboard('.copyCode');
			clipboard.on('success', e => {
				// this.$dialog.alert({
				// 	message: this.$t('xinzeng.fuzhichengg')
				// });
				this.$toast(this.$t('xinzeng.fuzhichengg'));
				// 释放内存
				clipboard.destroy();
			});
		},
		qrcode(address) {
			// console.log(address)
			console.log(address, 1111);

			new QRCode('share_page_qrcode', {
				width: 100, // 设置宽度，单位像素
				height: 100, // 设置高度，单位像素
				text: address // 设置二维码内容或跳转地址
			});
		},

		backToIndex() {
			this.$router.push('geren');
		},
		getInviteCode() {
			this.$post2('User/Api/Index/getMyInviteCode', {})
				.then(res => {
					this.inviteCode = res.refcode;
					this.link = res.link;
					// this.hasCode = 1;
					//
					// document.getElementById("qrcode").innerHTML = "";

					this.$nextTick(() => {
						this.qrcode(res.link);
					});
				})
				.catch(e => {
					// this.$toast.fail(e);
				});
		}
	}
};
</script>

<style lang="less">
#share_page_qrcode {
	img,
	canvas {
		display: block;
		width: 100%;
		height: 100%;
	}
}
</style>
<style lang="less" scoped>
.btn1,
.btn2 {
	color: #000;
	border: 0;
	font-size: 14px;
	background: none;
	padding: 0;
	margin-right: 0.36rem;
}

/*.yaoQingStyle {*/
/* position: absolute;
            bottom: 1.2rem;
            left: 0rem auto; */
/*margin-top: 11rem;*/
/*}*/

.titlePStyle {
	/* margin-bottom: 0.2rem; */
	margin-top: 0.2rem;
	/* padding-top: 0.1rem; */
	margin-right: 7rem;
	transform: rotate(180deg);
}

.qrcode {
	width: 0.3rem;
}

.fengXiangCode {
	position: absolute;
	bottom: 6.5rem;
	left: 3.2rem;
}

.spanStyle {
	margin-right: 7rem;
}

.op_container {
	flex: 1;
	text-align: left;
	margin-left: 0.5rem;
}

.title {
	// background-image: url('@/assets/imgs/share/backGroundImg2.png');
	background-position: center top;
	background-size: 100% 100%;
	// height: 100%;
	// width: 100%;
	// color: white;
	// text-align: center;
	&.bgVip {
		height: 100%;
		background-image: url('../../assets/imgs/share/poster.png');
	}

	.shareFooter {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 0.4rem 0.6rem;
		display: flex;
		align-items: center;
		background: linear-gradient(180deg, #fefeff 0%, #e6e6ff 100%);

		.codeImgStyle {
			width: 1.29rem;
			height: 1.29rem;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.yaoQingStyle {
			color: #000;
			font-size: 16px;
			margin: 0.15rem 0 0.2rem;
		}

		.rightBtn {
			margin-right: 10px;
		}
	}
}
.commonHead1 {
	background-image: url(../../assets/imgs/yao_bg.png);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: 100% 100%;
	padding: 0 0.3rem;
	.head {
		position: relative;
		height: 0.88rem;
		line-height: 0.88rem;
	}
	.left {
		position: absolute;
		left: 0;
		top: 0;
		.icon {
			width: 0.6rem;
			height: 0.88rem;
			display: flex;
			justify-content: center;
			align-items: center;
			.icon_arrL {
				border-top: 0.04rem solid #ffffff;
				border-left: 0.04rem solid #ffffff;
			}
			img {
				width: 0.4rem;
				height: 0.4rem;
			}
		}
	}
	.title {
		color: #ffffff;
		font-size: 0.32rem;
		text-align: center;
		text-transform: capitalize;
	}
}

.footerBtn {
	color: #ffffff;
	font-size: 0.58rem;
	width: 4.24rem;
	height: 1.54rem;
	padding-bottom: 0.14rem;
	border-radius: 0.77rem;
	background: #ff4b04 url(../../assets/imgs/share/VIP.png);
	background-size: 100% 100%;
	margin: 0 auto;
	span {
		font-weight: bold;
	}
}

// --------20200410[start]----------
.layoutBox {
	position: relative;
	// top: 0.88rem;
	padding-top: calc(env(safe-area-inset-top) + 1.08rem);
	left: 0;
	width: 100%;
	// z-index: 9999;
	&::before {
		content: '';
		height: calc(env(safe-area-inset-top) + 100vh);
		width: 100%;
		background: url(../../assets/imgs/yao_bg.png) no-repeat center top;
		background-size: 100% 100%;
		display: inline-block;
		position: absolute;
		top: 0;
	}
	// height: 100vh;
	// padding: 0.1rem 0.3rem 0.5rem;
	// background-image: url(../../assets/imgs/yao_bg.png);
	// background-repeat: no-repeat;
	// background-position: center top;
	// background-size: 100% 100%;
	// margin-top: -0.88rem;
	.shareAds {
		margin: 0 auto;
		width: 6.25rem;
		height: 1.27rem;
		position: relative;
		z-index: 10;
		img {
			display: block;
			width: 100%;
		}
	}
	.yao_head_zi {
		width: 4.17rem;
		height: 0.42rem;
		line-height: 0.42rem;
		margin: 0 auto;
		margin-top: 0.22rem;
		text-align: center;
		color: #2E62FF;
		font-size: 0.24rem;
		padding: 0 0.4rem;
		background-color: #FFFFFF;
		border-radius: 0.21rem;
		position: relative;
		z-index: 10;
	}
	.shareAds1 {
		position: relative;
		z-index: 10;
		margin: 0 auto;
		margin-top: -0.29rem;
		width: 6.02rem;
		height: 4.91rem;
		img {
			display: block;
			width: 100%;
		}
	}
	.shareSteps {
		position: relative;
		z-index: 10;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 0.88rem;
		padding: 0.32rem 0.64rem 0.55rem 0.64rem;
		.item {
			display: flex;
			flex-direction: column;
			align-items: center;
			.icon {
				font-size: 0.33rem;
				width: 0.89rem;
				height: 0.89rem;
				border-radius: 50%;
				background-color: #CCD9FF;
				color: #2E62FF;
				line-height: 0.89rem;
				text-align: center;
			}
			.txt {
				color: #333333;
				margin-top: 0.2rem;
				text-align: center;
				font-size: 0.28rem;
				width: 1.12rem;
			}
		}
		.item1 {
			display: flex;
			flex-direction: column;
			align-items: center;
			.icon {
				font-size: 0.33rem;
				width: 0.89rem;
				height: 0.89rem;
				border-radius: 50%;
				background-color: #CCD9FF;
				color: #2E62FF;
				line-height: 0.89rem;
				text-align: center;
			}
			.txt {
				margin-top: 0.2rem;
				text-align: center;
				font-size: 0.28rem;
				width: 1.68rem;
			}
		}
		.split {
			margin-top: -1.2rem;
			width: 0.38rem;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
	.shareInfo {
		position: relative;
		z-index: 10;
		background-color: #FFFFFF;
		border-radius: 0.21rem;
		margin: 0 0.2rem;
		margin-top: -0.64rem;
		.top {
			padding: 0.3rem 0.6rem 0 0.6rem;
		}
		.item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #333333;
			font-size: 0.24rem;
			.item_left {
				display: flex;
				align-items: center;
			}
			&:last-child {
				margin-bottom: 0;
			}
			.inp {
				font-family: PingFangSC-Medium;
				// width: 1.8rem;
				font-size: 0.32rem;
				color: #333;
				height: 0.7rem;
				line-height: 0.71rem;
				padding: 0 0.1rem 0 0.35rem;
				// background: #F6F7F9;
				// border-radius: .34rem;
				display: flex;
				justify-content: space-between;
				.link {
					width: 2.5rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			.copy {
				height: 0.41rem;
				font-size: 0.24rem;
				color: #2E62FF;
				border: 1px solid #527EFF;
				border-radius: 0.325rem;
			}
		}
		.item1 {
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #333333;
			font-size: 0.24rem;
			.item_left {
				display: flex;
				align-items: center;
			}
			&:last-child {
				margin-bottom: 0;
			}
			.inp {
				font-family: PingFangSC-Medium;
				// width: 1.8rem;
				font-size: 0.32rem;
				color: #333;
				height: 0.7rem;
				line-height: 0.71rem;
				padding: 0 0.1rem 0 0.118rem;
				// background: #F6F7F9;
				// border-radius: .34rem;
				display: flex;
				justify-content: space-between;
				.link {
					width: 2.5rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			.copy {
				height: 0.41rem;
				font-size: 0.24rem;
				color: #2E62FF;
				border: 1px solid #527EFF;
				border-radius: 0.325rem;
			}
		}
	}
	.shareReward {
		position: relative;
		z-index: 10;
		padding: 0.25rem;
		background: #ffffff;
		border-radius: 0.12rem;
		.caption {
			height: 0.5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0.15rem;
			h3 {
				font-size: 0.32rem;
			}
		}
	}
}
.posterLayout {
	position: fixed;
	z-index: 98;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(4, 4, 15, 0.4);
}
.posterCode {
	position: fixed;
	z-index: 99;
	left: 1.02rem;
	right: 1.02rem;
	top: 50%;
	width: 5.47rem;
	height: 6.06rem;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	background-image: url(../../assets/imgs/mianduimian.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	.title {
		font-family: PingFangSC-Regular;
		color: #333333;
		font-size: 0.32rem;
		margin: 0 auto;
		text-align: center;
		padding-top: 0.62rem;
		font-weight: bold;
	}
	.poster {
		img {
			display: block;
			width: 100%;
		}
		.code {
			margin-top: 0.58rem;
			margin-left: 1.07rem;
			width: 3.32rem;
			height: 3.36rem;
		}
	}
	.close {
		width: 0.8rem;
		height: 0.8rem;
		background: url(../../assets/imgs/share/icon_shut.png) no-repeat center center;
		background-size: 0.6rem 0.6rem;
		margin: 1.5rem auto 0;
	}
}
.bottom {
	position: relative;
	z-index: 10;
	padding: 0.25rem 0;
	display: flex;
	justify-content: space-between;
	margin: 0 0.2rem;
	.btn {
		color: #333333;
		font-size: 0.32rem;
		text-align: center;
		width: 3.26rem;
		height: 0.9rem;
		line-height: 0.9rem;
		background: #FFFFFF;
		border-radius: 0.16rem;
	}
}
// --------20200410[start]----------
</style>
